<template>
  <section class="static-banner">
    <div class="cover" />
    <div class="static-banner--logo d-lg-none">
      <img src="/assets/logo/logo-primary.png" alt="Tripmeister">
    </div>
    <div class="information" v-if="false">
      <button class="btn">Wo ist das?</button>
    </div>
    <div class="row static-banner-content">
      <div class="static-banner-info">
        <img src="/assets/logo/logo-primary.png" alt="Tripmeister" class="tripmeister-logo mb-4">
        <h1 class="banner-text">ECKERNFÖRDE</h1>
        <h2 class="banner-subtext mb-5">Reise, so wie du bist.</h2>
        <form action="" class="banner-search">

          <keyword-search class="keyword-search" @searchWordChanged="searchWordChanged" :isHome="true" :placeholderText="placeholderText" />
          <destination-select v-if="false" class="mb-4 destination-select" :isHome="true" @changedSelectedDestination="destinationChanged"/>

        </form>
      </div>
    </div>
  </section>
</template>

<script>
  import { screenSizeMixin } from '@/mixins/screenSizeMixin';

  export default {
    name: 'StaticBanner',
    mixins: [screenSizeMixin],
    components: {
      DestinationSelect: () => import('@/components/controls/DestinationSelect'),
      KeywordSearch: () => import('@/components/controls/KeywordSearch'),
    },
    data() {
      return {
        destination: "",
      }
    },
    computed:{
      destinations(){
        return this.$store.getters.getDestinations;
      },
      placeholderText(){
        if(this.$isMobileScreen){
          return 'Suchbegriff eingeben...'
        }
        else {
          return 'Suche nach Events, Locations und mehr...';
        }
      }
    },
    methods: {
      destinationChanged(){
        this.$emit('destinationChanged',this.destination);
      },
      searchWordChanged(word){
        this.$router.push({ name: 'Search', query: { keyword: word } })
      }
    }
  }
</script>
<style lang="scss" scoped>
  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  } 

  .banner-search {
    display: flex;

    .keyword-search {
      width: 100%;
      max-width: 100%;
    }

    .destination-select {
      margin: 0;
      width: 310px;
    }

  }

  .tripmeister-logo {
    width: 50%;

    @media(max-width: 991px){
      display: none;
    }
  }

  .banner-subtext {
    color: #3F3F3F
  }

  .static-banner-info {
    position:relative;
    background-color: rgba(255,255,255,0.4);
    border-radius: 4px;
    padding: 30px;
    margin-left: 100px;
    width: 450px;

    @media(max-width: 991px){
      margin-left: 50px;
    }

    @media(max-width:650px){
      margin-left: 30px;
      width: 420px;
    }

    @media(max-width:600px){
      margin-left: 0;
    }

    @media(max-width:500px){
      width: 350px;
    }
  }

</style>